import GameServices from '../../../services/api/games/game.services';
import ContantSetting from '../../../constants/setting/setting';
import BrandConfigStore from '../../../context/store/brand-config';
import GlobalStateStore from '../../../context/store/global-state';
import ModalStore from '../../../context/store/modal-pop-up';

const GameThumbnail = (p) => {
	const { game, themeNumber } = p;
	const { backgroundColor, colors } = BrandConfigStore(
		(state) => state.config.themeConfig.container
	);
	const { token } = GlobalStateStore((state) => state);
	const { setModal } = ModalStore((state) => ({ setModal: state.setModal }));

	let LightVibrant = game?.prominentColor?.LightVibrant;
	let Vibrant = game?.prominentColor?.Vibrant;
	let DarkVibrant = game?.prominentColor?.DarkVibrant;

	if (LightVibrant?.includes('.')) {
		LightVibrant = '#0C5750';
	}

	if (Vibrant?.includes('.')) {
		Vibrant = '#72B4AB';
	}

	if (DarkVibrant?.includes('.')) {
		DarkVibrant = '#72B4AB';
	}

	const onButtonClickHandler = async (type) => {
		if (token && token.split(':')[0].toLowerCase() !== 'sessiontoken') {
			try {
				if (ContantSetting?.brandId?.[ContantSetting?.currentBrandId]?.mobilePlayIframe) {
					const gameQuery = `${game.gpId}-${game.gpGameId}`;
					if (game.walletType === 'transfer_wallet') {
						setModal({ type: gameQuery, mode: 'topup' });
					} else {
						window.open(
							`/play/?gpId=${game.gpId}&gameId=${game.gpGameId}${
								type === 'demo' ? '&demo=true' : ''
							}`,
							'_blank'
						);
					}
				} else {
					const url = await GameServices.GetGameUrl({
						game: game,
						token: token
					});
					window.open(url.gameUrl, '_blank');
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			if (type === 'demo') {
				if (ContantSetting?.brandId?.[ContantSetting?.currentBrandId]?.mobilePlayIframe) {
					// const gameQuery = `${game.gpId}-${game.gpGameId}`;
					window.open(`/play/?gpId=${game.gpId}&gameId=${game.gpGameId}&demo=true`, '_blank');
				} else {
					const url = await GameServices.GetGameUrl({
						game: game,
						token: token
					});
					window.open(url.gameUrl, '_blank');
				}
			} else {
				setModal({ mode: 'login', type: 'login' });
			}
		}
	};

	const themeProps = {
		theme1: {
			container: {
				className: 'gameCard col-span-1'
			},
			subContainer1: {
				className:
					'gameCardHoveredEl duration-300 translate-y-full text-center absolute top-0 w-full h-full rounded-md flex flex-col justify-center items-center text-md z-50',
				style: { background: DarkVibrant || '#333333' }
			},
			buttons: [
				{
					key: 'realPlay',
					label: 'Real Play',
					props: {
						onClick: onButtonClickHandler,
						className:
							'flex h-1/2 w-full backdrop-blur-sm text-shadow-lg rounded-t-md font-raleWay text-lg font-bold cursor-pointer'
					}
				},
				{
					key: 'freePlay',
					label: game.demoUri ? 'Free Play' : '',
					props: {
						onClick: game.demoUri ? () => onButtonClickHandler('demo') : () => {},
						className:
							'flex h-1/2 w-full bg-black/40 backdrop-blur-sm rounded-b-md text-lg font-raleWay font-bold cursor-pointer'
					}
				}
			],
			subContainer2: {
				className: 'container-image cursor-pointer'
			},
			image: {
				className: 'mx-auto w-full aspect-square rounded-lg object-cover',
				src: game.s3ImageUrl.square200x200 || '/images/games/404.jpeg',
				onError: (e) => (e.target.src = '/images/games/404.jpeg'),
				alt: game.gameName
			},
			subChildrenContainer: {
				className:
					'w-full h-full absolute bottom-0 flex justify-center items-end p-1 py-3 rounded-lg pointer-events-none cursor-default z-30',
				style: { boxShadow: `inset 0 -4rem 1rem 0 ${DarkVibrant || '#333333'}` }
			},
			contentContainer: {
				className: 'flex h-full text-center items-end cursor-pointer'
			},
			contentContainer2: {
				className: 'flex h-full text-center items-end'
			},
			textContainer: {
				className: 'flex flex-col gap-1'
			},
			gameNameText: {
				className: `font-bold text-sm leading-none flex justify-center items-center capitalize  ${
					game.gameName.replace(/Live -/g, '').length > 25 ? 'text-sm' : ''
				}`
			},
			gpNameText: {
				className: 'font-thin text-xs leading-none flex justify-center items-center pt-px'
			}
		},
		theme2: {
			container: {
				className:
					'gameCard border-2 shadow-white shadowCard rounded-tr-3xl rounded-bl-3xl border-white py-4 col-span-1',
				// className: 'card-width-2 gameCard w-full h-full border-2 shadow-white shadowCard rounded-tr-3xl rounded-bl-3xl border-white py-4',
				style: {
					background: backgroundColor.tertiary,
					color: colors.primary
				}
			},
			contentContainer: {
				className: 'cursor-pointer'
			},
			subContainer1: {
				className:
					'gameCardHoveredEl duration-300 translate-y-full text-center absolute top-0 w-full h-full rounded-tr-3xl rounded-bl-3xl flex flex-col justify-center items-center text-md z-50',
				style: { background: DarkVibrant || '#333333' }
			},
			buttons: [
				{
					key: 'realPlay',
					label: 'Real Play',
					props: {
						onClick: onButtonClickHandler,
						className:
							'flex h-1/2 w-full backdrop-blur-sm text-shadow-lg rounded-tr-3xl font-raleWay text-lg font-bold cursor-pointer'
					}
				},
				{
					key: 'freePlay',
					label: game.demoUri ? 'Free Play' : '',
					props: {
						onClick: game.demoUri ? () => onButtonClickHandler('demo') : () => {},
						className:
							'flex h-1/2 w-full bg-black/40 backdrop-blur-sm rounded-bl-3xl text-lg font-raleWay font-bold cursor-pointer'
					}
				}
			],
			imageContainer: {
				className: 'mb-2 px-4'
			},
			image: {
				className:
					'mx-auto w-full aspect-2/1 rounded-tr-3xl rounded-bl-3xl object-cover object-top',
				src: game.s3ImageUrl.rectangle325x234 || '/images/games/404.jpeg',
				onError: (e) => (e.target.src = '/images/games/404.jpeg'),
				alt: game.gameName
			},
			subContainer2: {
				className:
					'w-full px-4 bottom-0  flex justify-start items-end pointer-events-none cursor-default z-30 cursor-pointer'
			},
			subChildrenContainer: {
				className: 'flex h-full  text-center items-end'
			},
			textContainer: {
				className: 'flex flex-col gap-1'
			},
			gameNameText: {
				className: `font-bold text-lg leading-none flex items-center truncate ${
					game.gameName.replace(/Live -/g, '').length > 25 ? 'text-sm' : ''
				}`
			},
			gpNameText: {
				className: 'font-thin text-base leading-none flex items-center pt-px"'
			}
		},
		theme3: {
			container: {
				className: 'gameCard rounded-sm col-span-1',
				// className: 'card-width-3 gameCard w-full h-full rounded-sm',
				style: {
					background: backgroundColor.tertiary,
					color: colors.primary
				}
			},
			contentContainer: {
				className: 'cursor-pointer'
			},
			subContainer1: {
				className:
					'gameCardHoveredEl h-full duration-300 translate-y-full text-center absolute top-0 w-full flex flex-col justify-center items-center text-md z-50',
				style: { background: DarkVibrant || '#333333' }
			},
			buttons: [
				{
					key: 'realPlay',
					label: 'Real Play',
					props: {
						onClick: onButtonClickHandler,
						className:
							'flex h-1/2 w-full backdrop-blur-sm text-shadow-lg font-raleWay text-lg font-bold cursor-pointer'
					}
				},
				{
					key: 'freePlay',
					label: game.demoUri ? 'Free Play' : '',
					props: {
						onClick: game.demoUri ? () => onButtonClickHandler('demo') : () => {},
						className:
							'flex h-1/2 w-full bg-black/40 backdrop-blur-sm text-lg font-raleWay font-bold cursor-pointer'
					}
				}
			],
			image: {
				className: 'mx-auto w-full object-cover object-top aspect-[3/2] h-full',
				src: game.s3ImageUrl.rectangle325x234 || '/images/games/404.jpeg',
				onError: (e) => (e.target.src = '/images/games/404.jpeg'),
				alt: game.gameName
			},
			content2Container: {
				className:
					'w-full px-2 py-4 bottom-0 flex justify-start items-end pointer-events-none cursor-default z-40 cursor-pointer',
				style: {
					background: backgroundColor.accent
				}
			},
			content2TextContainer: {
				className: 'flex flex-col gap-1 h-full w-full text-center'
			},
			text: [
				{
					key: 'gameName',
					label: game.gameName.replace(/Live -/g, ''),
					props: {
						className: `font-normal text-xl leading-none truncate text-start ${game.gameName.replace(
							/Live -/g,
							''
						)}`
					}
				},
				{
					key: 'gpName',
					label: game.gpName.replace(/Live -/g, '').replace(/([a-z])([A-Z])/g, '$1 $2'),
					props: {
						className: 'font-thin text-base leading-none flex items-center pt-px'
					}
				}
			]
		}
	};

	const theme = themeProps[`theme${themeNumber}`];

	return (
		<div {...theme.container}>
			{game.demoUri ? (
				<div {...theme.subContainer1}>
					{theme.buttons.map((b) => (
						<div key={b.key} {...b.props}>
							<span className="m-auto">{b.label}</span>
						</div>
					))}
				</div>
			) : null}
			<div {...theme.contentContainer} {...(!game.demoUri && { onClick: onButtonClickHandler })}>
				{themeNumber === 1 ? <ContainerOne theme={theme} game={game} /> : null}
				{themeNumber === 2 ? <ContainerTwo theme={theme} game={game} /> : null}
				{themeNumber === 3 ? <ContainerThree theme={theme} /> : null}
			</div>
		</div>
	);
};

const ContainerOne = (p) => {
	const { theme, game } = p;
	return (
		<div {...theme.subContainer2}>
			<img {...theme.image} loading="lazy" />
			<div {...theme.subChildrenContainer}>
				<div {...theme.contentContainer2}>
					<div {...theme.textContainer}>
						<p {...theme.gameNameText}>
							{game.gameName.replace(/Live -/g, '').replace(/\s/gi, ' ')}
						</p>
						<p {...theme.gpNameText}>
							{game.gpName.replace(/Live -/g, '').replace(/([a-z])([A-Z])/g, '$1 $2')}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const ContainerTwo = (p) => {
	const { theme, game } = p;
	return (
		<>
			<div {...theme.imageContainer}>
				<img {...theme.image} loading="lazy" />
			</div>
			<div {...theme.subContainer2}>
				<div {...theme.subChildrenContainer}>
					<div {...theme.contentContainer2}>
						<div {...theme.textContainer}>
							<p {...theme.gameNameText}>
								{game.gameName.replace(/Live -/g, '').replace(/\s/gi, ' ')}
							</p>
							<p {...theme.gpNameText}>
								{game.gpName.replace(/Live -/g, '').replace(/([a-z])([A-Z])/g, '$1 $2')}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const ContainerThree = (p) => {
	const { theme } = p;
	return (
		<>
			<img {...theme.image} loading="lazy" />
			<div {...theme.content2Container}>
				<div {...theme.content2TextContainer}>
					{theme.text.map((t) => (
						<p key={t.key} {...t.props}>
							{t.label}
						</p>
					))}
				</div>
			</div>
		</>
	);
};

export default GameThumbnail;
