import SettingCopa from './setting.copa';
import SettingTai from './setting.tai';

const ContantSetting = {
	currentBrandId: 'COPA',
	brandId: {
		COPA: {
			...SettingCopa,
		},
		TAI: {
			...SettingTai,
		},
	},
};

export default ContantSetting;
